const languagesList = [
  'en',
  'zh',
  'zh_HANT',
  'fr',
  'de',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'pl',
  'pt',
  'pt_br',
  'cn',
  'ru',
  'es',
  'th',
  'tr',
  'vi',
];

export default languagesList;
