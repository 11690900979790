import languagesList from '../constants/languages';

import routes from '../routes.json';

const pathsWithLanguagesCode = (): Record<string, string> => {
  const paths: Record<string, string> = { ...routes };

  languagesList.forEach(languageCode => {
    Object.entries(routes).forEach(([path, module]) => {
      paths[`/${languageCode}${path}`] = module as string;
    });
  });

  return paths;
};

export default pathsWithLanguagesCode;
