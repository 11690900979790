function Loader() {

  return (
    <div className="layout">
            <div className="layout__row top">
                <header className="header">
                    <div className="centered-content">
                        <div className="header__content">
                            <span className="header__logo-holder">
                        <i className="header__burger-holder">
                            <svg className="header__burger" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.25 6C3.25 5.58579 3.58579 5.25 4 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H4C3.58579 6.75 3.25 6.41421 3.25 6ZM3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12ZM4 17.25C3.58579 17.25 3.25 17.5858 3.25 18C3.25 18.4142 3.58579 18.75 4 18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H4Z" fill="black" />
                            </svg>
                        </i>
                        <img className="header__logo" src="https://cdn140.picsart.com/28410169050081638995.svg" alt="Picsart" />
                    </span>
                            <ul className="header__link-list">
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                                <li className="header__link-item">
                                    <i className="header__links-skeleton skeleton" />
                                </li>
                            </ul>
                            <ul className="header__actions-list">
                                <li className="header__actions-item">
                                    <i className="skeleton" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
            <div className="layout__row middle">
                <main className="main bg" />
            </div>
        </div>
  );
}

export default Loader;
